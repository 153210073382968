import { useMediaQuery } from "@mui/material";
import { Breakpoint, Theme, useTheme } from "@mui/material";
import moment from "moment";
import React from "react";
import {
  formatPriceString,
  isUrl,
  removeNullValues,
} from "../components/Utils";
import {
  ContentLink,
  HeaderButtonCallToAction,
  resolveContentUrl,
} from "../types/Content";
import {
  Extra,
  ExtraOption,
  ExtrasToBePaidForInput,
  ReservationExtra,
} from "@generated/types";
import { NextRouter } from "next/router";
import { ExtraDetailProps } from "../pages/mapped-templates/ExtraDetailsContainerTemplate";
import { ExtrasCardProps } from "../components/SemanticTheme/ExtrasCard";
import {
  ExtrasDateProps,
  ExtrasItemProps,
  ExtraTime,
  MaxAddedDateTime,
} from "../utils/common-types";
import { filterXSS } from "xss";
import { Guests } from "src/interfaces/guests";
import {
  BookingWidget,
  ColorEntityResponse,
  FaqAnswerPage,
  PageReferenceEntityResponse,
  StickyBanner,
} from "src/graphql/generated-strapi/types";
import { createHash } from "crypto";
import { getCookie, hasCookie } from "cookies-next";
import { CookiePreferences } from "@sykescottages/aristotle/lib/src/Models/CookiePreferences";
import { OptionsType } from "cookies-next/lib/types";
import axios from "axios";
import { LocationsDropdownItem } from "src/interfaces/bookingForm";
import { NonFAQContent } from "src/pages/PagePropertiesTemplate";

type QuestionAnswerList = {
  "@type": "Question";
  name: string; // Represents the question
  acceptedAnswer: Answer;
};

type Answer = {
  "@type": "Answer";
  text: string; // Represents the answer
};
export const capitalize = (string: string) => {
  if (typeof string !== "string") return "";
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
};

export const readContentValues = (obj: any, paths: any) => {
  const ret = Object.create(null);
  for (const k of paths) {
    ret[k] = obj?.[k] as string;
  }
  return ret;
};

export function pickValuesAsString<
  T extends { [index: string]: any },
  U extends keyof T,
>(obj: T, paths: U[]): { [P in U]: string } {
  return readContentValues(obj, paths);
}

// Helper function to read values and convert them to JSON strings
export const readContentValuesAsJSON = (obj: any, paths: any) => {
  const ret = Object.create(null);
  for (const k of paths) {
    ret[k] = JSON.stringify(obj?.[k]);
  }
  return ret;
};

// Main function that picks values as JSON strings
export function pickValuesAsJSON<
  T extends { [index: string]: any },
  U extends keyof T,
>(obj: T, paths: U[]): { [P in U]: string } {
  return readContentValuesAsJSON(obj, paths);
}

export function pickValuesAsBoolean<
  T extends { [index: string]: any },
  U extends keyof T,
>(obj: T, paths: U[]): { [P in U]: boolean } {
  return readContentValues(obj, paths);
}

export function pickValuesAsOptionalString<
  T extends { [index: string]: any },
  U extends keyof T,
>(obj: T, paths: U[]): { [P in U]: string | undefined } {
  return readContentValues(obj, paths);
}

export function pickValuesAsContentLink<
  T extends { [index: string]: any },
  U extends keyof T,
>(obj: T, paths: U[]): { [P in U]: ContentLink } {
  return readContentValues(obj, paths);
}

export type BannerProps = ReturnType<typeof makeBannerProps> | null;

export type StandardPageProps = ReturnType<typeof makeStandardPageProps>;

export const makeStandardPageProps = (content: any, primaryContent?: {}) => {
  return {
    ...pickValuesAsBoolean(content, [
      "RobotsNoFollow",
      "RobotsNoIndex",
      "RobotsNoArchive",
      "RobotsNoSnippet",
      "RobotsNoTranslate",
      "RobotsNoImageIndex",
      "FeefoReviewAPITag",
      "ShowCentralAddressData",
      "ShowCentralStructuredData",
    ]),
    ...pickValuesAsOptionalString(content, [
      "Title",
      "metaTitle",
      "metaDescription",
      "keywords",
      "metaRobots",
      "canonicalURL",
      "OGTitle",
      "OGDescription",
      "OgImage",
      "OGUrl",
      "OGSiteName",
      "OGLocale",
      "OGType",
      "OGFBAdmins",
      "bannerImgURL",
    ]),
    metaSocial: content?.["metaSocial"],
    ...pickValuesAsJSON(content, ["structuredData"]),
    primaryContent: primaryContent,
  };
};

export const makeBannerProps = (content: any) => {
  return {
    ...pickValuesAsBoolean(content, [
      "AutoplayVideo",
      "DontuseH1tagsinpagebanner",
      "CompactBanner",
    ]),
    ...pickValuesAsString(content, ["BannerTitle", "BannerSubTitle"]),
    ...pickValuesAsOptionalString(content, [
      "BannerVideoURL",
      "BannerVideoCallToActionText",
      "BannerCTALabel",
    ]),
    mobileBannerImage: content?.["MobileBannerImage"]?.data?.attributes?.url as
      | string
      | null,
    bannerImage: content?.["BannerImage"]?.data?.attributes?.url as
      | string
      | null,
    bannerVideoCallToActionRef: content?.["BannerCTAURL"]?.data?.attributes
      ?.PageRoute as string | null,
    pageBannerCallToActionRef: content?.["BannerCTAURL"]?.data?.attributes
      ?.PageRoute as string | null,
    bookingWidget: content?.["BookingWidget"]
      ? (content?.["BookingWidget"].data?.attributes as BookingWidget | null)
      : null,
    tabEnabled: content?.["TradingTab"]?.data?.attributes
      ?.TabEnabled as boolean,
    tabAlignment: content?.["TradingTab"]?.data?.attributes?.TabAlignment as
      | "left"
      | "right",
    tabBackgroundColour: content?.["TradingTab"]?.data?.attributes
      ?.TabBackgroundColour as ColorEntityResponse | null,
    tabTextLine1: content?.["TradingTab"]?.data?.attributes?.TabTextLine1 as
      | string
      | null,
    tabTextLine2: content?.["TradingTab"]?.data?.attributes?.TabTextLine2 as
      | string
      | null,
    tabTextLine3: content?.["TradingTab"]?.data?.attributes?.TabTextLine3 as
      | string
      | null,
    tabTextLine4: content?.["TradingTab"]?.data?.attributes?.TabTextLine4 as
      | string
      | null,
    tabVisibleFrom: content?.["TradingTab"]?.data?.attributes
      ?.TabVisibleFrom as number | null,
    tabVisibleTo: content?.["TradingTab"]?.data?.attributes?.TabVisibleTo as
      | number
      | null,
    tradingTabURL: content?.["TradingTab"]?.data?.attributes
      ?.TabCTAURL as PageReferenceEntityResponse | null,
    tabFontSizeLine1: content?.["TradingTab"]?.data?.attributes
      ?.TabFontSizeLine1 as number | null,
    tabFontSizeLine2: content?.["TradingTab"]?.data?.attributes
      ?.TabFontSizeLine2 as number | null,
    tabFontSizeLine3: content?.["TradingTab"]?.data?.attributes
      ?.TabFontSizeLine3 as number | null,
    tabFontSizeLine4: content?.["TradingTab"]?.data?.attributes
      ?.TabFontSizeLine4 as number | null,
    stickyBanner: content?.["StickyBanner"]?.data
      ?.attributes as StickyBanner | null,
    bannerComponent: content?.["BannerComponent"] !== null,
    children: content?.["children"] as React.ReactNode,
    pageImgQuality: content?.["ImageQuality"] as number,
  };
};

export type HeaderCmsData = ReturnType<typeof makeHeaderCmsData>;
export const makeHeaderCmsData = (content: any) =>
  content && {
    ...pickValuesAsString(content, [
      "DesktopTheme",
      "MobileTheme",
      "MyAccountLabel",
      "LoginLabel",
      "LogoutLabel",
      "LogoutTitle",
      "LogoutPopupDescription",
      "LogoutPopupConfirmCTALabel",
      "LogoutPopupCancelCTALabel",
    ]),
    headerButtons: content[
      "HeaderButtons"
    ] as any as HeaderButtonCallToAction[],
    ...pickValuesAsBoolean(content, ["alwaysVisibleHeader"]),
    ...pickValuesAsContentLink(content, [
      "myAccountIcon",
      "loginPageURL",
      "loginLogoutIcon",
    ]),
    headerLinks: content["headerRef"]?.expandedValue["headerLinks"],
    myAccountMenuItems:
      content["headerRef"]?.expandedValue["myAccountMenuItems"],
    bookNowButton: content["headerRef"]?.expandedValue["bookNowButton"],
    logoImage: content["headerRef"]?.expandedValue["logoImage"]
      .value as ContentLink,
    stickyBanner: content["stickyBanner"]?.expandedValue,
    stickyBannerMessage: content["stickyBanner"]?.expandedValue?.message?.value,
    stickyBannerBGColor:
      content["stickyBanner"]?.expandedValue?.backgroundColour?.value,
    stickyBannerCTAText:
      content["stickyBanner"]?.expandedValue?.primaryCallToActionText?.value,
    stickyBannerCTALink: resolveContentUrl(
      content["stickyBanner"]?.expandedValue?.primaryCallToActionRef
        ?.expandedValue?.contentLink,
    ),
    stickyBannerCloseIcon: resolveContentUrl(
      content["stickyBanner"]?.expandedValue?.closeIcon?.expandedValue
        ?.contentLink,
    ),
  };

export const parseQuery = (queryObject: { [key: string]: string }) =>
  filterXSS(
    "?" +
      Object.keys(queryObject)
        .map((key) => key + "=" + encodeURIComponent(queryObject[key]))
        .join("&"),
  );

export const generateBookingQueryString = (params: {
  startDate: string;
  endDate: string;
  locationIds: string[];
  guests?: Guests;
  dda?: boolean;
}) => {
  const dateFormat = "YYYY/MM/DD";
  const startDate = moment(params.startDate);
  const endDate = moment(params.endDate);
  const locations = params.locationIds;
  const { guests } = params;
  const adults = (guests?.adults || 0).toString();
  const bedrooms = (guests?.bedrooms || 0).toString();
  const children = (guests?.children || 0).toString();
  const infants = (guests?.infants || 0).toString();
  const pets = (guests?.pets || 0).toString();
  const dda = (params.dda || false).toString();

  return parseQuery({
    l: (locations.slice(0) || []).sort().join(","),
    d: endDate.diff(startDate, "days").toString(),
    sd: startDate.format(dateFormat),
    a: adults,
    b: bedrooms,
    c: children,
    i: infants,
    p: pets,
    dda: dda,
  });
};

export const createPathFromUrl = (url: string): string => {
  if (isUrl(url)) {
    const webUrl = new URL(url);
    return filterXSS(webUrl.pathname);
  }

  return url;
};

export type maxImgWidth = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
};

export const defaultMaxWidths = {
  xs: 600,
  sm: 960,
  md: 1280,
  lg: 1920,
  xl: 2400,
};

export const defaultMediaBlockMaxWidths = {
  xs: 800,
  sm: 800,
  md: 800,
  lg: 800,
  xl: 800,
};

export const defaultcaptionedImgMaxWidths = {
  xs: 640,
  sm: 640,
  md: 640,
  lg: 800,
  xl: 800,
};

export const defaultBookingWidths = {
  xs: 600,
  sm: 960,
  md: 600,
  lg: 600,
  xl: 900,
};

export const defaultCardWidths = {
  xs: 600,
  sm: 800,
  md: 600,
  lg: 600,
  xl: 600,
};

export const defaultEventCardWidths = {
  xs: 450,
  sm: 450,
  md: 450,
  lg: 450,
  xl: 450,
};

export const defaultTileWidths = {
  xs: 400,
  sm: 400,
  md: 400,
  lg: 400,
  xl: 400,
};

export const defaultSmallImageWidths = {
  xs: 150,
  sm: 150,
  md: 150,
  lg: 150,
  xl: 150,
};

export const defaultMapPopupImageWidths = {
  xs: 90,
  sm: 90,
  md: 90,
  lg: 90,
  xl: 90,
};

export const extrasCardImageWidths = {
  xs: 500,
  sm: 500,
  md: 500,
  lg: 500,
  xl: 500,
};

export const alternativeLocationsImageWidths = {
  xs: 500,
  sm: 500,
  md: 500,
  lg: 500,
  xl: 500,
};

export const cardImageWidths = {
  xs: 478,
  sm: 428,
  md: 588,
  lg: 588,
  xl: 588,
};

export const formatPrice = (
  num: number,
  currencySymbol = "£",
  isExp?: boolean,
): string => {
  const value = formatPriceString(num?.toString());
  return `${currencySymbol}${
    isExp ? value : (num || 0).toFixed(Number.isInteger(num) ? 0 : 2)
  }`;
};

export const makeHtml = (html: string) => ({ __html: html as string });

type BreakpointOrNull = Breakpoint | null;

/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
export const useWidth = () => {
  const theme: Theme = useTheme();
  const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
};

export const useUnload = (fn: EventListenerOrEventListenerObject) => {
  const refData = React.useRef(fn);

  React.useEffect(() => {
    const onUnload = refData.current;
    window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [refData]);
};
export const isPastDate = (date: string) => {
  const currentDate = new Date().setHours(0, 0, 0, 0);

  const isPastDate =
    new Date(moment(date).format("YYYY-MM-DD")).setHours(0, 0, 0, 0) <
    currentDate;

  return isPastDate;
};

export const isBookingSearchQueryParamsExistsInUrl = () => {
  const queryData = filterXSS(document.location.search);
  const queryParams = new URLSearchParams(queryData);
  const locationId = queryParams.get("l");
  const days = queryParams.get("d");
  const selectedDate = queryParams.get("sd");

  const isBookingSearchExists = !!locationId && !!days && !!selectedDate;

  return isBookingSearchExists;
};

export const getQueryAsStringArray = (query: string | string[]): string[] => {
  if (typeof query === "string") {
    if (query.includes(",")) {
      return query.split(",");
    }
    if (query.includes("%")) {
      return query.split("%");
    }
    return [filterXSS(query)];
  }
  const filteredQueryArray: string[] = query?.flatMap((item) =>
    filterXSS(item),
  );
  return filteredQueryArray;
};

export const getFirstQueryResult = (query: string | string[]): string => {
  return getQueryAsStringArray(query)?.[0] || "";
};

export const getBookingIdByQuery = (history: NextRouter) => {
  if (typeof window !== "undefined") {
    const { query } = history;
    const { bid, bId } = query;

    return getFirstQueryResult(bid) || getFirstQueryResult(bId);
  }
};

export type BookingDataFromQueryParams = {
  cabinReservationId: string;
  bookingId: string;
};

export const isCabinReservationIdExistsInUrl = (
  history: NextRouter,
): BookingDataFromQueryParams => {
  if (!!history && typeof window !== "undefined") {
    const { query } = history;
    const { crId, crid, bId, bid } = query;

    return {
      cabinReservationId: filterXSS(
        getFirstQueryResult(crId) || getFirstQueryResult(crid),
      ),
      bookingId: filterXSS(
        getFirstQueryResult(bId) || getFirstQueryResult(bid),
      ),
    };
  }
};

export const getCabinReservationIdByQuery = () => {
  if (typeof window !== "undefined") {
    return filterXSS(
      new URLSearchParams(window.location.search).get("crid") ||
        new URLSearchParams(window.location.search).get("crId"),
    );
  }
};

export const isGuid = (guid: string) =>
  /^[0-9a-f]{8}-?[0-9a-f]{4}-?[1-5][0-9a-f]{3}-?[89ab][0-9a-f]{3}-?[0-9a-f]{12}$/i.test(
    guid?.replace(/-/g, "")?.toLowerCase(),
  );

export const isValidArrayOfGuids = (list: string[]) => {
  return list.length > 0 && list.every(isGuid);
};

export const isDateNowOrInFuture = (date: string) => {
  try {
    const day = moment.utc(date);
    const now = moment.utc();
    const endOfDay = day?.hours(23).minutes(59).seconds(59);
    const startOfNow = now?.hours(0).minutes(0).seconds(0);
    const isDateValid = endOfDay.isSameOrAfter(startOfNow);
    return isDateValid;
  } catch (error: any) {
    return false;
  }
};

export const guidToString = (guid: string) =>
  guid?.replaceAll("-", "")?.toLowerCase();

export const generateExtrasQueryString = (params: {
  selectedTagIds: string[];
  sortMethod: string;
}) => {
  const selectedTags = params.selectedTagIds;
  const selectedSort = params.sortMethod;

  if (!!selectedTags?.length && !!selectedSort?.length) {
    return parseQuery({
      t: selectedTags.join(","),
      s: selectedSort,
    });
  } else if (!!selectedTags?.length) {
    return parseQuery({
      t: selectedTags.join(","),
    });
  } else if (!!selectedSort?.length) {
    return parseQuery({
      s: selectedSort,
    });
  }
};

export const cleanseGuid = (guid?: string) => {
  return guid?.trim()?.toLowerCase()?.replaceAll("-", "") as string;
};

export const getReservationExtras = (extras: ReservationExtra[]) => {
  return (extras || [])?.flatMap((extra) =>
    (extra?.reservationExtraOptions || [])?.map(
      (option): ExtrasToBePaidForInput => ({
        cabinReservationExtraID: option?.cabinReservationExtraId,
        extraOptionID: option?.extraOptionId,
        quantity: option?.quantity || 0,
      }),
    ),
  );
};

export const useNextRouterPathname = (router: NextRouter) => {
  // returns the pathname as string without any query params e.g "/account/booking"

  const { asPath } = router; // includes query params by default
  const pathnameWithoutQueryParams = filterXSS(asPath?.split("?")?.[0]);

  return pathnameWithoutQueryParams;
};

export const useNextRouterPathnameWithQuery = (router: NextRouter) => {
  // returns the pathname as string with query params e.g "/account/booking?l=123"

  const { asPath } = router; // includes query params by default
  const pathnameWithQueryParams = filterXSS(asPath);

  return pathnameWithQueryParams;
};

export enum Scenario {
  AddOneOfSingleOption = "AddOneOfSingleOption",
  AddSelectedQuantityOfSingleOption = "AddSelectedQuantityOfSingleOption",
  AddSelectedQuantityOfMultipleOption = "AddSelectedQuantityOfMultipleOption",
  AddOneOfSpecifiedDay = "AddOneOfSpecifiedDay",
  AddOneOfSpecifiedDayAndTime = "AddOneOfSpecifiedDayAndTime",
  Complex = "Complex",
  ChooseMore = "ChooseMore",
  Standard = "Standard",
}

export const generateExtraItems = (extraOptions: ExtraOption[]) => {
  return extraOptions.map((option) => ({
    id: option?.extraOptionId as string,
    value: option?.extraOptionPrice as number,
    label: option?.extraOptionName as string,
    priceCheck: option?.priceCheck as string,
    priceExpiry: option?.priceExpiry,
    currentStockLevel: option?.currentStockLevel as number,
    stockControlled: !!option?.stockControlled,
  }));
};

export const convertDates = (
  startDate: string,
  endDate: string,
  extra: Extra,
) => {
  const reservationStartDate = moment(startDate);
  const reservationEndDate = moment(endDate);
  const start = extra.bookableOnArrivalDay
    ? reservationStartDate
    : reservationStartDate.add(1, "day");
  const end = extra.bookableOnDepartureDay
    ? reservationEndDate
    : reservationEndDate.add(-1, "day");

  const now = start.clone();
  const dates: string[] = [];

  while (now.isSameOrBefore(end)) {
    const dateOfTheWeek = now.clone().isoWeekday();
    const extrasDays = [
      extra.extraDateMon,
      extra.extraDateTues,
      extra.extraDateWed,
      extra.extraDateThurs,
      extra.extraDateFri,
      extra.extraDateSat,
      extra.extraDateSun,
    ];
    if (extrasDays[dateOfTheWeek - 1]) {
      dates.push(now.toISOString());
    }

    now.add(1, "days");
  }

  return dates.map((date) => ({ isoDate: date }));
};

export const makeExtraDetails = <
  Props extends ExtraDetailProps | ExtrasCardProps,
>(
  reservationStartDate: string,
  reservationEndDate: string,
  extra: Extra,
  props: Props,
  scenario?: Scenario,
  quickLearnMoreLabel?: string,
  quickLearnMoreUrl?: string,
  onLearnMoreAction?: () => void,
) => {
  if (!props.overrideComplexScenarioHide) {
    props.showLearnMoreButton = true;
  }
  props.ctaLabel = quickLearnMoreLabel;
  props.disableCtaButton = !quickLearnMoreUrl;
  props.ctaUrl = quickLearnMoreUrl;
  switch (scenario) {
    case Scenario.AddOneOfSingleOption: {
      props.hasOnlyOneDefaultValue = true;
      break;
    }
    case Scenario.AddSelectedQuantityOfSingleOption:
    case Scenario.AddSelectedQuantityOfMultipleOption:
    case Scenario.Complex: {
      const dateSelection = convertDates(
        reservationStartDate,
        reservationEndDate,
        extra,
      );
      const timeSelection = extra.hasAMPM;

      if (
        scenario === Scenario.Complex &&
        dateSelection.length > 0 &&
        timeSelection &&
        !props.overrideComplexScenarioHide
      ) {
        // business requirement: only up to 2 selection options may be shown from items, dates, time selections
        // unless in "override" which is triggered on Extra Details Page to allow user to make selections.
        props.items = [];
        props.hideSubmitButton = true;
        props.includeTime = false;
      } else {
        props.dates = dateSelection;
        props.includeTime = !!timeSelection;
      }

      break;
    }
    case Scenario.AddOneOfSpecifiedDay:
    case Scenario.AddOneOfSpecifiedDayAndTime: {
      props.hasOnlyOneDefaultValue = true;
      props.dates = convertDates(
        reservationStartDate,
        reservationEndDate,
        extra,
      );
      props.includeTime =
        extra.extraTileScenario === Scenario.AddOneOfSpecifiedDayAndTime;
      break;
    }
    default:
      break;
  }

  return props;
};

export const setMaxAdded = (
  extrasProps: ExtrasCardProps | ExtraDetailProps,
  extra: Extra,
  basketExtras: ReservationExtra[],
) => {
  let maxedExtrasProps = { ...extrasProps };
  const extrasPropsDates = extrasProps.dates;

  switch (extra?.extraTileScenario) {
    case Scenario.AddOneOfSingleOption:
      if (
        !!basketExtras[0]?.maximumQuantity &&
        basketExtras[0]?.maximumQuantity > 0
      ) {
        maxedExtrasProps.items[0] = {
          ...maxedExtrasProps.items[0],
          maxAdded: !!basketExtras[0]?.reservationExtraOptions?.[0]?.quantity,
        };
      }
      break;
    case Scenario.AddSelectedQuantityOfSingleOption:
      const basketQuantitySum =
        basketExtras[0]?.reservationExtraOptions?.reduce(
          (prev, curr) => prev + (curr?.quantity || 0),
          0,
        ) ?? 0;
      const maxQuantity = !!basketExtras[0]?.maximumQuantity
        ? basketExtras[0].maximumQuantity
        : 0;

      maxedExtrasProps.items[0] = {
        ...maxedExtrasProps.items[0],
        maxAdded: maxQuantity !== 0 && basketQuantitySum >= maxQuantity,
      };

      break;
    case Scenario.AddSelectedQuantityOfMultipleOption:
      const basketMaxQuantity = !!basketExtras[0]?.maximumQuantity
        ? basketExtras[0]?.maximumQuantity
        : 0;

      const mappedExtraItemsProps = maxedExtrasProps.items.map((item) => {
        const maxAddedCheck = basketExtras[0]?.reservationExtraOptions?.find(
          (extraOption) =>
            extraOption?.extraOptionId === item.id &&
            (extraOption?.quantity || 0) >= basketMaxQuantity,
        );

        return {
          ...item,
          maxAdded: !!basketMaxQuantity && !!maxAddedCheck,
        };
      });

      maxedExtrasProps.items = mappedExtraItemsProps;
      break;
    case Scenario.AddOneOfSpecifiedDay:
      maxedExtrasProps.dates = extrasPropsDates?.map((date) => ({
        ...date,
        maxAdded: !!basketExtras?.find(
          (be) => be.calendarDate === date.isoDate,
        ),
      })) as ExtrasDateProps[];
      break;
    case Scenario.AddOneOfSpecifiedDayAndTime:
      maxedExtrasProps.dates = extrasPropsDates?.map((date) => {
        const maxDateAdded =
          basketExtras?.filter((be) => be.calendarDate === date.isoDate)
            .length === 2;
        const amMaxed =
          basketExtras?.filter((be) => be.aMPM === ExtraTime.AM).length ===
          extrasPropsDates?.length;
        const pmMaxed =
          basketExtras?.filter((be) => be.aMPM === ExtraTime.PM).length ===
          extrasPropsDates?.length;

        return {
          ...date,
          maxAdded: maxDateAdded,
          maxTime: {
            am: amMaxed,
            pm: pmMaxed,
          },
        };
      });
      break;
    case Scenario.Complex:
      let mappedExtraItems = [...extrasProps.items];
      const maxBasketExtrasLength = extra.hasAMPM
        ? (extrasPropsDates?.length || 0) * 2
        : extrasPropsDates?.length || 0;

      // Max out on items
      if (!!basketExtras?.length) {
        mappedExtraItems = setMaxedOutItems(basketExtras, extrasProps);
      }

      // Max out on dates
      const filteredBasketExtrasAM = basketExtras.filter(
        (be) => be.aMPM === ExtraTime.AM,
      );
      const filteredBasketExtrasPM = basketExtras.filter(
        (be) => be.aMPM === ExtraTime.PM,
      );

      const mappedDates = extrasPropsDates?.map((date) => {
        if (extra.hasAMPM) {
          return getMaxedDatesWithTime(
            date,
            basketExtras,
            extrasProps,
            filteredBasketExtrasAM,
            filteredBasketExtrasPM,
          );
        } else {
          return getMaxedDatesWithoutTime(date, basketExtras, extrasProps);
        }
      });

      maxedExtrasProps = {
        ...maxedExtrasProps,
        items: mappedExtraItems,
        dates: mappedDates,
      };
      break;
    default:
      break;
  }

  return maxedExtrasProps;
};

const checkMaxAdded = (
  extras: ReservationExtra[],
  extraOptions: ExtrasItemProps[],
) => {
  return (
    extras?.filter(
      (extra) =>
        extra?.reservationExtraOptions?.filter(
          (option) =>
            extra?.maximumQuantity &&
            extra.maximumQuantity !== 0 &&
            (option?.quantity || 0) >= extra.maximumQuantity,
        )?.length || 0 === extraOptions.length,
    ).length === extras.length
  );
};

const setMaxedOutItems = (
  basketExtras: ReservationExtra[],
  extrasProps: ExtrasCardProps | ExtraDetailProps,
) => {
  return extrasProps.items.map((extraItem) => {
    const maxedOutItems = basketExtras.filter((basketExtra) =>
      basketExtra.reservationExtraOptions?.find(
        (reservationExtra) =>
          reservationExtra?.extraOptionId === extraItem.id &&
          !!extrasProps?.maxQuantity &&
          (reservationExtra?.quantity || 0) >= extrasProps.maxQuantity,
      ),
    );

    const maxAddedDateTime = !!maxedOutItems.length
      ? (maxedOutItems.map((maxItem) => ({
          date: maxItem.calendarDate,
          time: maxItem.aMPM,
        })) as MaxAddedDateTime[])
      : undefined;

    const numberOfDates = extrasProps?.dates?.length || 0;
    const numberOfDateTimeOptions =
      numberOfDates > 0
        ? extrasProps.includeTime
          ? numberOfDates * 2
          : numberOfDates
        : 0;

    const maxAdded =
      numberOfDateTimeOptions > 0 && !!maxAddedDateTime
        ? maxAddedDateTime.length >= numberOfDateTimeOptions
        : !!maxedOutItems.length;

    return {
      ...extraItem,
      maxAdded: maxAdded,
      maxAddedDateTime: maxAddedDateTime,
    };
  });
};

const getMaxedDatesWithTime = (
  date: ExtrasDateProps,
  basketExtras: ReservationExtra[],
  extrasProps: ExtrasCardProps | ExtraDetailProps,
  filteredAM: ReservationExtra[],
  filteredPM: ReservationExtra[],
) => {
  const filteredBasketExtrasDates = basketExtras.filter(
    (be) => be.calendarDate === date.isoDate,
  );
  let maxAddedCheck = false;
  let maxAmCheck = false;
  let maxPmCheck = false;

  if (
    (extrasProps?.dates?.length || 0) > 0 &&
    filteredBasketExtrasDates.length > 0
  ) {
    if (filteredAM.length > 0) {
      maxAmCheck = checkMaxAdded(filteredAM, extrasProps.items);
    }

    if (filteredPM.length > 0) {
      maxPmCheck = checkMaxAdded(filteredPM, extrasProps.items);
    }

    maxAddedCheck = maxAmCheck && maxPmCheck;
  }

  return {
    ...date,
    maxAdded: maxAddedCheck,
    maxTime: {
      am: maxAmCheck,
      pm: maxPmCheck,
    },
  };
};

const getMaxedDatesWithoutTime = (
  date: ExtrasDateProps,
  basketExtras: ReservationExtra[],
  extrasProps: ExtrasCardProps | ExtraDetailProps,
) => {
  const foundMatchingDate = basketExtras?.find(
    (basketExtra) => basketExtra.calendarDate === date.isoDate,
  );

  if (!foundMatchingDate) return date;

  if (
    !!foundMatchingDate?.reservationExtraOptions?.length &&
    foundMatchingDate.reservationExtraOptions.length < extrasProps.items.length
  ) {
    return date;
  }

  const maxAddedCheck = !!foundMatchingDate?.maximumQuantity
    ? (foundMatchingDate.reservationExtraOptions?.filter(
        (option) =>
          (option?.quantity || 0) >= (foundMatchingDate?.maximumQuantity || 0),
      )?.length ?? 0) >= extrasProps.items.length
    : false;

  return {
    ...date,
    maxAdded: maxAddedCheck,
  };
};

export const getMonthNumberFromName = (monthName: string) => {
  const monthNumbers = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };

  if (monthName in monthNumbers) return monthNumbers[monthName];
  else return new Date().getMonth() + 1;
};

export const getPartialMonthNameFromMonthNumber = (monthNumber: number) => {
  const monthNames = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  if (monthNumber in monthNames) return monthNames[monthNumber];
  else return false;
};

export const getHashHex = async (message: string) => {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join(""); // convert bytes to hex string
  return hashHex;
};

export const getHashHexCode = (message: string): string => {
  const hash = createHash("sha256");
  hash.update(message);
  return hash.digest("hex");
};

export enum ConsentType {
  approved = "approved",
  declined = "declined",
  unspecified = "unspecified",
}

export const getAnalyticsConsentPreference = (
  options?: OptionsType,
): ConsentType => {
  const consentPrefs = generateCookieConsentMapping(options);
  if (!!consentPrefs) {
    const analyticsConsent = consentPrefs["analytics_cookies"];
    if (analyticsConsent === true) {
      return ConsentType.approved;
    }
    if (analyticsConsent === false) {
      return ConsentType.declined;
    }
    return ConsentType.unspecified;
  } else {
    return ConsentType.unspecified;
  }
};

export const isAnalyticsConsentUnspecified = (
  options?: OptionsType,
): boolean => {
  const analyticsConsent = getAnalyticsConsentPreference(options);
  return analyticsConsent === ConsentType.unspecified;
};

export const isAnalyticsConsentDeclined = (options?: OptionsType): boolean => {
  const analyticsConsent = getAnalyticsConsentPreference(options);
  return analyticsConsent === ConsentType.declined;
};

export const generateCookieConsentMapping = (
  options?: OptionsType,
): CookiePreferences | null => {
  const consentCookieKey = "OptanonConsent";
  const consentSpecified = "OptanonAlertBoxClosed";
  const keyPairValueLength = 2;

  if (
    !hasCookie(consentCookieKey, options) ||
    !hasCookie(consentSpecified, options)
  ) {
    return null;
  }

  const consentData = getCookie(consentCookieKey, options) as string;

  if (!consentData) {
    return null;
  }

  const consentMap: { groups?: string } = {};

  decodeURIComponent(consentData)
    .split("&")
    .map((val) => {
      const split = val.split("=");
      if (keyPairValueLength === split.length) {
        consentMap[split[0]] = split[1];
      }
    });

  if (undefined === consentMap.groups) {
    return null;
  }

  const cookiePreferences: CookiePreferences = {
    strictly_necessary_cookies: false,
    analytics_cookies: false,
    personalisation_cookies: false,
    targeting_and_marketing_cookies: false,
    social_media_cookies: false,
  };

  const preferenceMap = {
    C0001: "strictly_necessary_cookies",
    C0002: "analytics_cookies",
    C0003: "personalisation_cookies",
    C0004: "targeting_and_marketing_cookies",
    C0005: "social_media_cookies",
  };

  consentMap.groups.split(",").map((val) => {
    const split = val.split(":");
    if (keyPairValueLength === split.length) {
      cookiePreferences[preferenceMap[split[0]]] = "1" === split[1];
    }
  });

  return cookiePreferences;
};

interface UTMData {
  medium: string | null;
  source: string | null;
  campaign: string | null;
  queryString: string | null;
  gad: boolean | null;
  gclid: string | null;
}

export const parseUTMFromUrl = (url: string): UTMData => {
  try {
    if (!!url) {
      const urlObj = new URL(url);

      const medium = urlObj.searchParams.get("utm_medium");
      const source = urlObj.searchParams.get("utm_source");
      const campaign = urlObj.searchParams.get("utm_campaign");
      const gadValue = urlObj.searchParams.get("gad");
      const gad = gadValue === "1";
      const gclid = urlObj.searchParams.get("gclid");
      const queryString = urlObj.search;

      return { medium, source, campaign, queryString, gad, gclid };
    } else {
      return {
        medium: null,
        source: null,
        campaign: null,
        queryString: null,
        gad: null,
        gclid: null,
      };
    }
  } catch (error) {
    // Handle invalid URL
    return {
      medium: null,
      source: null,
      campaign: null,
      queryString: null,
      gad: null,
      gclid: null,
    };
  }
};

export const getDeviceType = (): "tablet" | "mobile" | "desktop" => {
  const userAgent: string = navigator.userAgent;

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      userAgent,
    )
  ) {
    return "mobile";
  }

  return "desktop";
};

export const getBrowser = (userAgent: string) => {
  if (userAgent.includes("Firefox/") && !userAgent.includes("Seamonkey/"))
    return "Firefox";
  if (userAgent.includes("Seamonkey/")) return "Seamonkey";
  if (
    userAgent.includes("Chrome/") &&
    !userAgent.includes("Chromium/") &&
    !userAgent.includes("Edg")
  )
    return "Chrome";
  if (userAgent.includes("Chromium/")) return "Chromium";
  if (
    userAgent.includes("Safari/") &&
    !userAgent.includes("Chromium/") &&
    !userAgent.includes("Chrome/")
  )
    return "Safari";
  if (userAgent.includes("OPR/")) return "Opera 15+";
  if (userAgent.includes("Opera/")) return "Opera 12-";
  if (userAgent.includes("[FB_IAB/") || userAgent.includes("[FBAN/"))
    return "Facebook";
  if (userAgent.includes("SamsungBrowser/")) return "Samsung Internet";
  if (userAgent.includes("Edg/")) return "Edge";
  if (userAgent.includes("Instagram")) return "Instagram";

  return "Unknown";
};

export const getSSTData = async () => {
  const serverSideTrackingUrl =
    process.env.NEXT_PUBLIC_SERVER_SIDE_TRACKING_URL;
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.get(serverSideTrackingUrl, { headers });
    return response.data;
  } catch (error) {
    console.error("Error fetching tracking data:", error);
    return null;
  }
};

export const getLocationsInUrl = (
  bookableLocations: LocationsDropdownItem[],
) => {
  const listOfLocations = bookableLocations?.map((location) => {
    return {
      name: location?.name?.toLowerCase()?.replaceAll(" ", "-"),
      id: location?.id,
    };
  });
  let locationsInUrl = [];
  const pathname = window.location.pathname;
  if (listOfLocations?.length > 0)
    listOfLocations.map((location) => {
      if (location?.name && pathname.includes(location.name)) {
        locationsInUrl.push(location);
      }
    });

  return locationsInUrl;
};

export const getFAQAnswerStructuredData = (
  faqAnswerPageContent?: FaqAnswerPage,
) => {
  const question = faqAnswerPageContent?.Question;
  const answer = faqAnswerPageContent?.AnswerText1;

  const faqMarkup = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: question,
        acceptedAnswer: {
          "@type": "Answer",
          text: answer,
        },
      },
    ],
  };
  const structuredData = question && answer ? JSON.stringify(faqMarkup) : null;

  return structuredData;
};

export const getNonFAQAnswerStructuredData = (faqAnswerPageContent?: any) => {
  const QandAList: QuestionAnswerList[] =
    faqAnswerPageContent &&
    faqAnswerPageContent[0]?.faq_comp?.data?.map((item) => ({
      "@type": "Question",
      name: item?.attributes?.Question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item?.attributes?.Answer,
      },
    }));

  const faqMarkup = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: QandAList?.length > 0 ? QandAList : [],
  };
  const cleanedStructuredData = removeNullValues(faqMarkup);

  const structuredData =
    QandAList?.length > 0 && cleanedStructuredData
      ? cleanedStructuredData
      : null;

  return structuredData;
};
